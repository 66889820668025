import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { AppUser } from "../structs/base";

import { BackendService } from "./backend.service";
import { OfflineService } from "./offline.service";
import { first, map } from "rxjs/operators";

@Injectable()
export class UsersService {
  constructor(private backend: BackendService, private offlineService: OfflineService) {}

  /**
   * Calls the api that generate a jwt for the logged user
   */
  public generateJwt(): Observable<string> {
    return this.backend.get("/users/api/jwt/");
  }

  /**
   * WARNING: the name is misleading.
   * @param userID
   */
  public getTeamMemberFromUserID(userID: number): Observable<AppUser> {
    return new Observable(observer => {
      this.offlineService.getConfig("users").subscribe(
        users => {
          let usr = users.find(user => user.get_user_id === userID);
          observer.next(usr);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  getTeamMember(teamMemberID: number): Observable<AppUser> {
    return this.offlineService.getConfig("users").pipe(
      first(),
      map(users => users.find(teamMember => teamMember.id === teamMemberID))
    );
  }
}
