<ion-list lines="full">
  <input-item [legend]="'Equipment label' | translate" [hidden]="!showLabelInput">
    <ion-input
      type="text"
      placeholder="-"
      [(ngModel)]="assetEditService.asset.label"
      maxlength="500"
      [clearInput]="true"
      [disabled]="assetEditService.readOnly"
      (focusout)="toggleLabelInput()"
      #labelInput></ion-input>
  </input-item>
  <legend-item [legend]="'Equipment label' | translate" [hidden]="showLabelInput" (click)="toggleLabelInput()">
    {{ assetEditService.asset.label }}
    <span *ngIf="!assetEditService.addMode && futureAssetMode">{{ "in project" | translate }}</span>
  </legend-item>

  <legend-item
    [legend]="'Asset type' | translate"
    [showArrow]="true"
    (click)="clickAssetType($event)"
    *ngIf="canChangeAssetType()">
    <span *ngIf="assetEditService.getAssetType()">{{ assetEditService.getAssetType().name }}</span>
    <span *ngIf="!assetEditService.getAssetType()">-</span>
  </legend-item>

  <legend-item [legend]="'Asset type' | translate" [disabled]="true" [showArrow]="true" *ngIf="!canChangeAssetType()">
    <span *ngIf="assetEditService.getAssetType()">{{ assetEditService.getAssetType().name }}</span>
    <span *ngIf="!assetEditService.getAssetType() && parent.assetType">{{ parent.assetType.name }}</span>
    <span *ngIf="!assetEditService.getAssetType() && !parent.assetType">-</span>
  </legend-item>

  <!-- <legend-item *ngIf="showImportStatus" [legend]="'Import status' | translate" (click)="confirmImport()">
    {{ importStatusLabel }}
  </legend-item> -->

  <input-item legend="{{ 'Quantity' | translate }} {{ unit }}">
    <ion-input
      type="number"
      placeholder="-"
      [(ngModel)]="assetEditService.asset.quantity"
      (ionBlur)="saveQuantity($event)"
      (ionBlur)="quantityChecker($event)"
      min="0"
      [disabled]="assetEditService.readOnly"></ion-input>
  </input-item>

  <input-item legend="{{ 'Number' | translate }}" *ngIf="assetEditService.getAssetType()?.show_equipment_count">
    <ion-input
      type="number"
      placeholder="-"
      [(ngModel)]="assetEditService.asset.equipment_count"
      (keyup)="equipmentCountChecker($event)"
      [disabled]="assetEditService.readOnly"></ion-input>
  </input-item>

  <!-- Height / Nombre d'étages -->
  <input-item legend="{{ 'Number of floors' | translate }}" *ngIf="assetEditService.getAssetType()?.heightSupport">
    <ion-input
      type="number"
      placeholder="-"
      [(ngModel)]="assetEditService.asset.height"
      (keyup)="heightChecker($event)"
      [disabled]="assetEditService.readOnly"></ion-input>
  </input-item>

  <input-item
    *ngIf="assetEditService.isOwnershipTypeEnabled && ownershipTypes?.length"
    [legend]="'Type GTP' | translate">
    <ion-select
      okText="{{ 'OK' | translate }}"
      cancelText="{{ 'Cancel' | translate }}"
      interface="action-sheet"
      [(ngModel)]="selectedOwnershipTypeId"
      placeholder="-"
      (ngModelChange)="ownershipTypeChanged()"
      [disabled]="assetEditService.readOnly"
      *ngIf="assetEditService.asset.parent === null">
      <ion-select-option *ngFor="let type of ownershipTypes" [value]="type.id">{{ type.name }}</ion-select-option>
    </ion-select>
    <ion-select
      okText="{{ 'OK' | translate }}"
      cancelText="{{ 'Cancel' | translate }}"
      [(ngModel)]="parent.ownershipType"
      placeholder="-"
      [disabled]="true"
      *ngIf="parent !== null">
      <ion-select-option [value]="parent.ownershipType">
        <span *ngIf="parent.ownershipType">{{ parent.ownershipType.name }}</span>
        <span *ngIf="!parent.ownershipType">-</span>
      </ion-select-option>
    </ion-select>
  </input-item>

  <legend-item [legend]="'Perimeter' | translate" (click)="openMonoPerimeters()">
    {{ getMonoPerimeterName(selectedMonoPerimeterId) }}
  </legend-item>

  <input-item [legend]="'Floor' | translate" *ngIf="show_floor">
    <ion-select
      okText="{{ 'OK' | translate }}"
      cancelText="{{ 'Cancel' | translate }}"
      interface="action-sheet"
      [(ngModel)]="assetEditService.selectedAssetFloor"
      (ngModelChange)="saveFloor()"
      placeholder="-"
      [disabled]="assetEditService.readOnly">
      <ion-select-option *ngFor="let floor of assetEditService.assetFloor$ | async" [value]="floor.id">
        {{ floor.name }}
      </ion-select-option>
    </ion-select>
  </input-item>

  <input-item [legend]="'Zone' | translate" *ngIf="zones.length > 1">
    <ion-select
      okText="{{ 'OK' | translate }}"
      cancelText="{{ 'Cancel' | translate }}"
      interface="action-sheet"
      [(ngModel)]="selectedZoneId"
      placeholder="-"
      (ngModelChange)="zoneChanged()"
      [disabled]="assetEditService.readOnly || !assetEditService.getAssetType() || !selectedMonoPerimeterId">
      <ion-select-option *ngFor="let zone of zones" [value]="zone.id">{{ zone.name }}</ion-select-option>
    </ion-select>
  </input-item>

  <ion-row class="grey-divider">
    <ion-col>
      <ion-note color="primary">
        {{ "Optional" | translate }}
      </ion-note>
    </ion-col>
  </ion-row>

  <input-item [legend]="'Refrigerant type' | translate" *ngIf="hasRefrigerantType()">
    <ion-select
      okText="{{ 'OK' | translate }}"
      cancelText="{{ 'Cancel' | translate }}"
      interface="action-sheet"
      [(ngModel)]="selectedRefrigerantTypedId"
      (ngModelChange)="refrigerantTypeChanged()"
      [disabled]="assetEditService.readOnly">
      <ion-select-option>-</ion-select-option>
      <ion-select-option *ngFor="let choice of refrigerantTypes" [value]="choice.id">
        {{ choice.name }}
      </ion-select-option>
    </ion-select>
  </input-item>

  <input-item
    legend="{{ 'Latest refrigerant charge declaration' | translate }}"
    *ngIf="hasRefrigerantType() && selectedRefrigerantTypedId">
    <ion-input
      type="number"
      placeholder="-"
      [(ngModel)]="assetEditService.asset.latestRefrigerantDeclaration"
      (focusout)="saveLatestRefrigerantDeclaration()"
      (keyup)="latestRefrigerantDeclarationChecker($event)"
      [disabled]="assetEditService.readOnly"></ion-input>
  </input-item>

  <input-item [legend]="'Zone details' | translate">
    <ion-textarea
      autosize
      placeholder="-"
      [(ngModel)]="assetEditService.asset.zoneDetails"
      (focusout)="saveZoneDetails($event)"
      [disabled]="assetEditService.readOnly"></ion-textarea>
  </input-item>

  <legend-item
    [legend]="'Parent asset' | translate"
    [showArrow]="true"
    *ngIf="isAssetAffiliationEnabled && parent"
    (click)="openAssetParent(parent)">
    <span>{{ parent.label }}</span>
  </legend-item>

  <input-item legend="{{ 'Most recently documented energy yield (%)' | translate }}" *ngIf="hasEnergyYieldSupport()">
    <ion-input
      type="number"
      placeholder="-"
      [(ngModel)]="assetEditService.asset.latest_energy_yield_declaration"
      (keyup)="energyYieldChecker($event)"></ion-input>
  </input-item>

  <legend-item
    [legend]="'Replaced by' | translate"
    *ngIf="assetEditService.asset.replacedBy && assetEditService.asset.step === archivedStep">
    <div class="content text-style1" (click)="openOtherAsset(assetEditService.asset.replacedBy)">
      {{ assetEditService.asset.replacedBy.label | defaultValue: assetEditService.asset.replacedBy.assetType.name }}
    </div>
  </legend-item>

  <legend-item
    [legend]="'Replacement of' | translate"
    *ngIf="assetEditService.asset.replacementOf && assetEditService.asset.step === activeStep">
    <div class="content text-style1" (click)="openOtherAsset(assetEditService.asset.replacementOf)">
      {{
        assetEditService.asset.replacementOf.label | defaultValue: assetEditService.asset.replacementOf.assetType.name
      }}
      <span *ngIf="assetEditService.asset.replacementOf.step === archivedStep">({{ "archived" | translate }})</span>
    </div>
  </legend-item>

  <legend-item [legend]="'QR Code' | translate" *ngIf="assetEditService.asset.qrcode" (click)="manageQrCode()">
    <i class="fal fa-qrcode"></i>
  </legend-item>

  <legend-item [legend]="'Barcode' | translate" *ngIf="assetEditService.asset.barcode" (click)="manageBarCode()">
    <i class="fal fa-barcode"></i>
    {{ assetEditService.asset.barcode }}
  </legend-item>

  <!-- Code jumeau numérique (GMAO / CMMS) -->
  <!-- <input-item
      *ngIf="assetEditService.getAssetType()?.showMaintenanceCode"
      [legend]="'Maintenance code' | translate"
    >
      <ion-input
        type="text"
        placeholder="-"
        [(ngModel)]="assetEditService.asset.maintenanceCode"
        (focusout)="saveMaintenanceCode($event)"
        maxlength="50"
        [disabled]="assetEditService.readOnly"
      ></ion-input>
    </input-item> -->

  <input-item [legend]="'Brand' | translate" *ngIf="hasBrandSupport()">
    <ion-input
      type="text"
      placeholder="-"
      [(ngModel)]="assetEditService.asset.brand"
      (focusout)="saveBrand($event)"
      maxlength="100"
      [disabled]="assetEditService.readOnly"></ion-input>
  </input-item>

  <input-item [legend]="'Model' | translate" *ngIf="hasModelSupport()">
    <ion-input
      type="text"
      placeholder="-"
      [(ngModel)]="assetEditService.asset.model"
      (focusout)="saveModel($event)"
      maxlength="100"
      [disabled]="assetEditService.readOnly"></ion-input>
  </input-item>

  <input-item [legend]="'Power source' | translate" *ngIf="hasPowerSourceSupport()">
    <ion-select
      okText="{{ 'OK' | translate }}"
      cancelText="{{ 'Cancel' | translate }}"
      interface="action-sheet"
      [(ngModel)]="selectedPowerSourceId"
      placeholder="-"
      (ngModelChange)="powerSourceChanged()"
      [disabled]="assetEditService.readOnly">
      <ion-select-option *ngFor="let powerSource of powerSources" [value]="powerSource.id">
        {{ powerSource.name }}
      </ion-select-option>
    </ion-select>
  </input-item>

  <input-item legend="{{ 'Power' | translate }} {{ measur_unit }}" *ngIf="hasPowerSupport()">
    <ion-input
      type="text"
      placeholder="-"
      [(ngModel)]="assetEditService.asset.power"
      (focusout)="savePower($event)"
      maxlength="100"
      [disabled]="assetEditService.readOnly"></ion-input>
  </input-item>

  <input-item [legend]="'Heat Transfer Coefficient (U)' | translate" *ngIf="hasHeatTransferCoefficientU()">
    <ion-select
      okText="{{ 'OK' | translate }}"
      cancelText="{{ 'Cancel' | translate }}"
      interface="action-sheet"
      [(ngModel)]="selectedHeatTransferCoefficientId"
      placeholder="-"
      (ngModelChange)="heatTransferCoefficientChanged()"
      [disabled]="assetEditService.readOnly">
      <ion-select-option
        *ngFor="let heatTransferCoefficient of heatTransferCoefficients"
        [value]="heatTransferCoefficient.id">
        {{ heatTransferCoefficient.name }}
      </ion-select-option>
    </ion-select>
  </input-item>

  <input-item [legend]="'Source of Heat Transfer Coefficient (U)' | translate" *ngIf="hasHeatTransferCoefficientU()">
    <ion-select
      okText="{{ 'OK' | translate }}"
      cancelText="{{ 'Cancel' | translate }}"
      interface="action-sheet"
      [(ngModel)]="selectedSourceInfoHeatTransferCoefficientId"
      placeholder="-"
      (ngModelChange)="sourceInfoHeatTransferCoefficientChanged()"
      [disabled]="assetEditService.readOnly">
      <ion-select-option
        *ngFor="let sourceInfoHeatTransferCoefficient of sourceInfoHeatTransferCoefficients"
        [value]="sourceInfoHeatTransferCoefficient.id">
        {{ sourceInfoHeatTransferCoefficient.name }}
      </ion-select-option>
    </ion-select>
  </input-item>

  <input-item [legend]="'Thermal Resistance Coefficient (R)' | translate" *ngIf="hasThermalResistanceCoefficientR()">
    <ion-select
      okText="{{ 'OK' | translate }}"
      cancelText="{{ 'Cancel' | translate }}"
      interface="action-sheet"
      [(ngModel)]="selectedThermalResistanceCoefficientId"
      placeholder="-"
      (ngModelChange)="thermalResistanceCoefficientChanged()"
      [disabled]="assetEditService.readOnly">
      <ion-select-option
        *ngFor="let thermalResistanceCoefficient of thermalResistanceCoefficients"
        [value]="thermalResistanceCoefficient.id">
        {{ thermalResistanceCoefficient.name }}
      </ion-select-option>
    </ion-select>
  </input-item>

  <input-item
    [legend]="'Source of Thermal Resistance Coefficient (R)' | translate"
    *ngIf="hasThermalResistanceCoefficientR()">
    <ion-select
      okText="{{ 'OK' | translate }}"
      cancelText="{{ 'Cancel' | translate }}"
      interface="action-sheet"
      [(ngModel)]="selectedSourceInfoThermalResistanceCoefficientId"
      placeholder="-"
      (ngModelChange)="sourceInfoThermalResistanceCoefficientChanged()"
      [disabled]="assetEditService.readOnly">
      <ion-select-option
        *ngFor="let sourceInfoThermalResistanceCoefficient of sourceInfoThermalResistanceCoefficients"
        [value]="sourceInfoThermalResistanceCoefficient.id">
        {{ sourceInfoThermalResistanceCoefficient.name }}
      </ion-select-option>
    </ion-select>
  </input-item>

  <input-item [legend]="'Served zone' | translate" *ngIf="hasServedZoneSupport()">
    <ion-input
      type="text"
      placeholder="-"
      [(ngModel)]="assetEditService.asset.servedZone"
      (focusout)="saveServedZone($event)"
      maxlength="100"
      [disabled]="assetEditService.readOnly"></ion-input>
  </input-item>

  <input-item [legend]="'Comments' | translate" lines="none">
    <ion-textarea
      autoGrow
      placeholder="-"
      [(ngModel)]="assetEditService.asset.comments"
      (focusout)="saveComments($event)"
      [disabled]="assetEditService.readOnly"></ion-textarea>
  </input-item>
</ion-list>
