<legend-item
  [legend]="itemLabel"
  [showArrow]="false"
  [itemOptions]="itemOptions"
  [index]="index"
  [chaptersGroup]="$any(chaptersGroup)"
  [padding]="padding"
  [labelClass]="'text-style5'"
  [picture]="
    showPicture
      ? $any(chaptersGroup.group).pictures.length > 0
        ? $any(chaptersGroup.group).pictures[0]
        : pictureEmpty
      : null
  "
  [hasChildren]="hasChildren"
  [isCollapsed]="isCollapsed"
  [showChevronButtons]="showChevronButtons"
  (toggleCollapse)="toggleMultiPerimeterChildren()"
  (itemClicked)="openChaptersGroup(chaptersGroup)"
  (pictureClicked)="onPictureClicked.emit()"
  [isChild]="isChild"
  [isRoadmap]="showPicture"
  [color]="color">
  <div class="text-style1" *ngIf="showAudits">
    <ion-text>{{ getControlPointSentence() }}</ion-text>
  </div>
  <div class="assets" style="text-transform: lowercase" *ngIf="!showAudits">
    {{ getLinkedAssetsSentence() }}
  </div>
  <div class="budget" *ngIf="!showAudits">{{ chaptersGroup.budget | currency }} {{ "planned" | translate }}</div>
  <div class="pie-chart-div" *ngIf="chaptersGroup.total !== 0">
    <pie-chart [percentage]="getRoadmapScore()"></pie-chart>
  </div>
</legend-item>
